<template>
  <div>
    <div v-if="isLoading" class="skeleton-loader">
      <div class="skeleton-item"></div>
    </div>

    <template v-else>
      <template v-if="!userData">
        <AppFormAccount
          v-if="showRegistrationForm"
          @userSaved="handleUserSaved"
        />
        <AppBonus
          v-else
          @showRegistration="showRegistrationForm = true"
        />
      </template>
      <AppFriends
        v-else
        :userData="userData"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppFormAccount from './AppFormAccount.vue';
import AppBonus from './AppBonus.vue';
import AppFriends from './AppFriends.vue';

export default {
  computed: {
    ...mapState('auth', ['userData', 'isLoading'])
  },
  components: {
    AppBonus,
    AppFriends,
    AppFormAccount
  },
  data() {
    return {
      showRegistrationForm: false
    }
  },
  methods: {
    async handleUserSaved(userData) {
      try {
        await this.$store.dispatch('auth/updateUserData', userData);
      } catch (error) {
        console.error('Failed to save user data:', error);
      }
    }
  }
};
</script>