<template>
  <div class="slider" @touchstart="startTouch" @touchmove.prevent="moveTouch" @touchend="endTouch">
    <div class="slides" :style="slidesStyle">
      <div class="slide" v-for="(slide, index) in slidesData" :key="index">
        <div class="slide-content">
          {{ slide.content }}
          <!-- Условно вставим содержимое только для первого и второго слайда -->
          <div v-if="index === 0" class="stock1">
            <img class="doctor1" src="/static/img/doctor.png" alt="Doctor Image">
            <div class="flex1">
              <p class="p__main__stock">Месяц борьбы<br>с алкоголизмом</p>
              <p class="p__second__stock">Бесплатная консультация<br> и выезд <span class="span__stock">со скидкой 50%</span></p>
              <button class="more">Подробнее</button>
            </div>
          </div>
          <div v-if="index === 1" class="stock1">
            <div class="fake-img1">
              <div class="circle1"></div>
              <img class="doctor2" src="/static/img/camera.svg" >
              <div class="circle2"></div>
            </div>
            <div class="flex1">
              <p class="p__main__stock">Скидка 35%<br>на первый вызов</p>
              <p class="p__second__stock">Вызовите врача сегодня<br><span class="span__stock">со скидкой 35%</span></p>
              <button class="more">Подробнее</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      startX: 0,
      translateX: 0,
      slidesData: [
        { content: "" },
        { content: "" }
      ]
    };
  },
  computed: {
    slidesStyle() {
      return {
        transform: `translateX(-${this.currentIndex * 100}%)`,
        transition: 'transform 0.5s ease'
      };
    }
  },
  methods: {
    startTouch(event) {
      this.startX = event.touches[0].clientX;
    },
    moveTouch(event) {
      const currentX = event.touches[0].clientX;
      this.translateX = this.startX - currentX; // вычисляем смещение
    },
    endTouch() {
      if (this.translateX > 50 && this.currentIndex < this.slidesData.length - 1) {
        this.currentIndex++;
      } else if (this.translateX < -50 && this.currentIndex > 0) {
        this.currentIndex--;
      }
      this.translateX = 0; // сбрасываем для следующего слайда
    }
  }
};
</script>

<style scoped>
.slider {
  overflow: hidden;
  width: 100%;
}

.slides {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  min-width: 100%; /* Занимает всю ширину контейнера */

  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-content {
  padding: 20px;
  text-align: center;
}

.stock1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor1 {
  margin-right: 20px; /* Отступ от изображения */
}

.doctor2{
  transform: translate(0%,-5%);
}

.circle1{
  box-shadow: 0px 0px 28.4px 0px #0000001A;
  background-color: #FFFFFF33;
  width: 40px;
  height: 40px;
  border-radius: 15px 3px 100px 3px;
}

.circle2{
  box-shadow: 0px 0px 28.4px 0px #0000001A;
  background-color: #FFFFFF33;
  width: 40px;
  height: 40px;
  border-radius: 100px 0px 0px 0px;
  transform: translate(205%,-70%);
}

.fake-img1{
  background-color: #43D5CA;
  height: 100%;
  width: 50%;
  margin-right: 20px;
  border-radius: 5px 0px 0px 5px;
}

.flex1 {
  text-align: left; /* Выровнять текст влево */
}

.p__main__stock, .p__second__stock {
  margin: 0; /* Убираем отступы по умолчанию */
}

.more {
  background-color: #43D5CA;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}
</style>
