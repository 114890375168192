<template>
  <div class="mini-apps_centre">
    <div class="number_order">
      <p><span style="font-weight: 600">Заказ #{{ order.number }}</span></p>
    </div>
    <div class="content_number_order">
      <p>
        Дата заказа: {{ order.date }}<br>
        <br>
        <span style="font-weight: 700">Услуги:</span><br>
        <template v-for="(service, index) in order.services" :key="index">
          <ul>
            <li>{{ service.name }} - {{ service.price }} ₽</li>
          </ul>
        </template>
        <br>
        <span style="font-weight: 700">Итого:</span> {{ order.total }} ₽
      </p>
    </div>
   <button class="repeat" @click="repeatOrder">Повторить заказ</button>
  </div>
</template>

<script>
export default {
  name: 'NumberOrder',
  data() {
    return {
      order: {
        number: '',
        date: '',
        services: [],
        total: 0
      },
      loading: true,
      error: null,
      telegramUserId: null,
      orderErrors: []
    }
  },
  mounted() {
    // Get Telegram user ID directly from Telegram WebApp
    if (window.Telegram?.WebApp?.initDataUnsafe?.user?.id) {
      this.telegramUserId = window.Telegram.WebApp.initDataUnsafe.user.id;
      this.fetchOrderDetails();
    } else {
      this.error = 'Не удалось получить ID пользователя Telegram';
      this.loading = false;
    }
  },
  methods: {
    async fetchOrderDetails() {
      const orderId = this.$route.params.id;

      if (!orderId) {
        this.error = 'Order ID is missing';
        this.loading = false;
        return;
      }

      try {
        const url = `https://dedovagency.ru/backend/orders/${orderId}?telegram_user_id=${this.telegramUserId}`;
        console.log('Fetching order from:', url);

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Received data:', data);
        this.order = data;
      } catch (error) {
        console.error('Error fetching order details:', error);
        this.error = `Ошибка при загрузке заказа: ${error.message}`;
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      this.$router.push('/friends');
    },
   
   async repeatOrder() {
      // Clear previous errors
      this.orderErrors = [];

      if (!this.order.services || this.order.services.length === 0) {
        this.orderErrors.push('Не найдено услуг для повторения.');
        return;
      }

      try {
        //let allAdded = true; // To track if all products were successfully added

        for (const service of this.order.services) {
          const product = this.$store.getters.findProductByName(service.name);

          if (product) {
            await this.$store.dispatch('addToCart', {
              id: product.id,
              name: product.name,
              price: product.price,
              quantity: service.quantity || 1 // Use service.quantity if available
            });
          } else {
            // allAdded = false;
            this.orderErrors.push(`Продукт для услуги "${service.name}" не найден и не был добавлен в корзину.`);
          }
        }
        // Redirect to the cart page
        this.$router.push({ name: 'Cart' });
      } catch (error) {
        console.error('Ошибка при повторении заказа:', error);
        this.orderErrors.push('Произошла ошибка при добавлении услуг в корзину. Попробуйте еще раз.');
      }
    }
  }  
}
</script>

<style scoped>
.mini-apps_centre{
  margin-top: 10vh;
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.content_number_order{
  margin-top: 3vh;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px;
}

.number_order{
  display: flex;
}

.cross1{
  background-color: #0000000A;
  padding: 5px;
  margin-left: auto;
  cursor: pointer;
}

.repeat{
  background-color: #43D5CA;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  min-width: 42px;
  padding: 12px 14px 12px 14px;
  gap: 8px;
  border-radius: 12px;
  color: #ffffff;
  border: 0;
  margin-top: 2vh;
  cursor: pointer;
}
</style>
