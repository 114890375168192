<template>
  <div class="friends">
<!--    <img class="friend" src="/static/img/friend.png" alt="Empty Cart">-->
<!--    <p class="friend-label">Приглашайте друзей</p>
    <p class="friend-tip">Приведите друга и получите 100 медкоинов (10 медкоинов = 1 рубль)!</p>
    <div class="balance-widget">
      <p class="label__balance-widget">Баланс медкоинов</p>
      <p class="balance__balance-widget">0 <b>М</b></p>
      <div>
        <button class="btn-invite-friend">
          <a :href="telegramShareUrl" class="tgme_widget_share_btn" target="_blank">
            <i class="tgme_widget_share_icon"></i>
            <span class="tgme_widget_share_label">Пригласить друзей</span>
          </a>
        </button>
        <button class="clipboard-invite-link" @click="copyInviteLink">
          <img src="/static/img/clipboard-icon.png" alt="Copy">
        </button>
        <div v-if="showCopyNotification" class="copy-notification">
          Скопирована пригласительная ссылка!
        </div>
      </div>
    </div>-->

    <div style="display: flex; margin-top: 30px">
      <p style="margin-left: 5vw; font-weight: 600; font-size: 17px;">Заказы</p>
      <p style="margin-left: auto; margin-right: 6vw;" class="see__all">Смотреть все</p>
    </div>

    <ul>
        <li v-for="order in orders.slice(0,5)" :key="order.id">
    <div class="item-order" @click="viewOrderDetails(order.id)">
          <div class="info__item-order">
            <div style="display: flex;">
              <p class="number-order">Заказ №{{ order.number }}</p>
              <p class="status-order">{{ order.status }}</p>
            </div>
            <p class="date-create-order">Дата создания: {{ order.date }}</p>
            <p class="total-price-order">Сумма: {{ order.total }}</p>
          </div>
          <button><img src="/static/img/chevron_right_16.svg" alt="View"></button>
        </div>
      </li>
    </ul>

    <p style="margin-left: 5vw; font-weight: 600; font-size: 17px;">Ваш аккаунт</p>
    <div class="acc-form">
      <div class="input_data">
        <p class="data-account-label">Телефон</p>
        <p class="data-account">{{ userData?.phone || '—' }}</p>
      </div>
      <div class="input_data">
        <p class="data-account-label">Имя</p>
        <p class="data-account">{{ userData?.full_name || '—' }}</p>
      </div>
      <div class="input_data">
        <p class="data-account-label">Адрес</p>
        <p class="data-account">{{ userData?.address || '—' }}</p>
      </div>
      <div class="input_data">
        <p class="data-account-label">Дата рождения</p>
        <p class="data-account">{{ userData?.birth_date || '—' }}</p>
      </div>
    </div>

    <button class="all-promotions" @click="showEditForm">Редактировать</button>

    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <AppFormAccount
          :initial-user-data="userDataWithTelegram"
          @close="closeModal"
          @user-saved="handleUserSaved"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppFormAccount from "@/components/AppFormAccount.vue";

export default {
  name: 'AppFriends',
  components: {
    AppFormAccount
  },
  props: {
    userData: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      orders: [],
      showModal: false,
      showCopyNotification: false,
      refCode: this.generateRefCode()
    };
  },
  computed: {
    userDataWithTelegram() {
      const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id;
      return {
        ...this.userData,
        telegram_user_id: this.userData?.telegram_user_id || telegramId || null
      };
    },
    telegramShareUrl() {
      return `https://t.me/share/url?url=https://t.me/promedline_bot?start=ref${this.refCode}&text=Привет! Promedline в Москве и МО - вызов врача на дом.`;
    }
  },
  mounted() {
    this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      const telegramUserId = this.userDataWithTelegram.telegram_user_id;

      if (!telegramUserId) {
        console.error("Telegram user ID is required to fetch orders.");
        return;
      }

      try {
        const response = await fetch(`https://dedovagency.ru/backend/orders?telegram_user_id=${telegramUserId}`);
        if (!response.ok) throw new Error('Failed to fetch orders');
        this.orders = await response.json();
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    },
    viewOrderDetails(orderId) {
      this.$router.push(`/order/${orderId}`);
    },
    generateRefCode() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return Array.from({ length: 10 }, () =>
        characters.charAt(Math.floor(Math.random() * characters.length))
      ).join('');
    },
    showEditForm() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    handleUserSaved(updatedUserData) {
      this.$emit('user-updated', updatedUserData);
      this.closeModal();
    },
    async copyInviteLink() {
      const inviteLink = `https://t.me/promedline?start=ref${this.refCode}`;
      try {
        await navigator.clipboard.writeText(inviteLink);
        this.showCopyNotification = true;
        setTimeout(() => {
          this.showCopyNotification = false;
        }, 2000);
      } catch (err) {
        console.error('Ошибка при копировании:', err);
      }
    }
  }
};
</script>

<style scoped>
/* Add specific styles for the friends component */
.friends {
  padding: 20px;
  padding-bottom: 100px;
}
ul, li {
  padding-left: 1vw;
  list-style-type: none;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    z-index: 1234;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}
.copy-notification {
  margin-top: 10px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
</style>

