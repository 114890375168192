<!-- VideoGallery.vue -->
<template>
  <div class="video-gallery">
    <!-- Horizontal scrollable container -->
    <div class="video-scroll-container">
      <!-- Video preview cards -->
      <div v-for="(video, index) in videos" :key="index" class="video-preview-card">
        <!-- Preview image with play button -->
        <div class="preview-container" @click="openVideo(video)">
          <img :src="video.thumbnail" :alt="'Preview ' + (index + 1)" class="video-thumbnail">
          <div class="play-button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="48" height="48">
              <path d="M8 5v14l11-7z"/>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!-- Video popup modal -->
    <div v-if="selectedVideo" class="video-modal" @click.self="closeVideo">
      <div class="modal-content">
        <button class="close-button" @click="closeVideo">&times;</button>
        <video
          ref="videoPlayer"
          controls
          :src="selectedVideo.src"
          @ended="closeVideo"
        >
          Your browser doesn't support video playback.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoGallery',
  data() {
    return {
      selectedVideo: null,
      videos: [
        {
          id: 1,
          src: '/static/reviews/review1.mp4',
          thumbnail: '/static/reviews/thumbnails/review1.jpg',
        },
        {
          id: 2,
          src: '/static/reviews/review2.mp4',
          thumbnail: '/static/reviews/thumbnails/review2.jpg',
        },
        {
          id: 3,
          src: '/static/reviews/review3.mp4',
          thumbnail: '/static/reviews/thumbnails/review3.jpg',
        },
        {
          id: 4,
          src: '/static/reviews/review4.mp4',
          thumbnail: '/static/reviews/thumbnails/review4.jpg',
        },
        {
          id: 5,
          src: '/static/reviews/review5.mp4',
          thumbnail: '/static/reviews/thumbnails/review5.jpg',
        },
        {
          id: 6,
          src: '/static/reviews/review6.mp4',
          thumbnail: '/static/reviews/thumbnails/review6.jpg',
        },
        {
          id: 7,
          src: '/static/reviews/review7.mp4',
          thumbnail: '/static/reviews/thumbnails/review7.jpg',
        },
        {
          id: 8,
          src: '/static/reviews/review8.mp4',
          thumbnail: '/static/reviews/thumbnails/review8.jpg',
        },
        {
          id: 9,
          src: '/static/reviews/review9.mp4',
          thumbnail: '/static/reviews/thumbnails/review9.jpg',
        },
        {
          id: 10,
          src: '/static/reviews/review10.mp4',
          thumbnail: '/static/reviews/thumbnails/review10.jpg',
        }
      ],
    }
  },
  methods: {
    openVideo(video) {
      this.selectedVideo = video;
      // Wait for DOM update then play
      this.$nextTick(() => {
        if (this.$refs.videoPlayer) {
          this.$refs.videoPlayer.play();
        }
      });
    },
    closeVideo() {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause();
      }
      this.selectedVideo = null;
    }
  },
  // Clean up when component is destroyed
  beforeUnmount() {
    if (this.selectedVideo) {
      this.closeVideo();
    }
  }
}
</script>

<style scoped>
.video-gallery {
  width: 100%;
  position: relative;
}

.video-scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}

.video-preview-card {
  flex: 0 0 auto;
  width: 10rem;
}

.preview-container {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 9/16;
}

.video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s;
}

.preview-container:hover .video-thumbnail {
  transform: scale(1.05);
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.preview-container:hover .play-button {
  background: rgba(0, 0, 0, 0.8);
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 1200px;
  aspect-ratio: 16/9;
}

.modal-content video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
  z-index: 1001;
}

@media (max-width: 768px) {
  .video-preview-card {
    width: 10rem;
  }

  .close-button {
    top: -40px;
    right: 0;
  }
}
</style>