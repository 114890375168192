<template>
  <div>
    <div v-if="isLoading" class="skeleton-loader">
      <div class="skeleton-item"></div>
    </div>

    <template v-else>
      <FormOrder
        v-if="!userData"
        @userSaved="handleUserSaved"
      />
      <OneMoment
        v-else
        :userData="userData"
      />
    </template>
  </div>
</template>

<script>
import FormOrder from './AppFormOrder.vue';
import OneMoment from './OneMoment.vue';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['userData', 'isLoading'])
  },
  components: {
    FormOrder,
    OneMoment
  },
  methods: {
    async handleUserSaved(userData) {
      try {
        await this.$store.dispatch('auth/updateUserData', userData);
      } catch (error) {
        console.error('Failed to save user data:', error);
      }
    }
  }
};
</script>