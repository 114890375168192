	<!-- pro-med-line/src/components/AppHome.vue -->
<template>
  <div class="background">
    <div class="screen">
      <div class="main__content">
        <div class="vizov">
          <div class="main__container">
            <p class="p__first__container">ВЫЗОВ ВРАЧА<br> НА ДОМ<br> В МОСКВЕ И МО</p>
            <button class="call_on_home" @click="makeOrderRightAway">Вызов врача</button>
          </div>
          <img class="women1" src="/static/img/womanhighres.png">
        </div>
        <div class="categories">
          <div class="kateg">
            <p class="p__kat">Категории</p>
            <button class="see__all" @click="openModal">Смотреть все</button>
          </div>
          <router-link
              v-for="category in featuredCategories"
              :key="category.index"
              :to="{ name: 'catalog', params: { category: category.index.toString() }}"
              class="kat1"
              style="cursor: pointer;"
              @click="handleCategorySelect(category.index)"
          >
            <img class="icon__in" :src="category.image">
            <div class="main__text__kat">
              <p class="text1">{{ category.name }}</p>
              <p class="price">от {{ category.price }} ₽</p>
            </div>
          </router-link>


        </div>
        <div class="p__kat">
          <div class="stocks">
            <p class="p__stock">Акции</p>
            <router-link class="see_all-link" to="/promotions">
              <p class="see__all">Смотреть все</p>
            </router-link>
          </div>
          <div class="stock1">
            <img class="doctor1" src="/static/img/doctor.png">
            <div class="flex1">
              <p class="p__main__stock">Выезд<br>со скидкой</p>
              <p class="p__second__stock">Для новых клиентов<br> выезд врача <span
                  class="span__stock">со скидкой 5%!</span></p>
              <button @click="navigateToItem(60)" class="more">Подробнее</button>
            </div>
          </div>
        </div>
        <div class="our__doctors">
          <div class="doctors">
            <p class="p__kat">Наши врачи</p>
            <!--            <button class="see__all" @click="openModal2">Смотреть все</button>-->
          </div>
          <div style="overflow-x: scroll; overflow-y: hidden;white-space: nowrap;">
            <div class="anketa1">
              <img class="doctor_Uliya" src="/static/img/Терапевт.png">
              <div class="name">
                <p class="fullname">Танукина Юлия</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-терапевт</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 26 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Хирург.png">
              <div class="name">
                <p class="fullname">Шишков Михаил</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-хирург</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 14 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Онколог.png">
              <div class="name">
                <p class="fullname">Савницкий Роман</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-онколог</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 13 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Кардиолог.png">
              <div class="name">
                <p class="fullname">Ахметов Рустам</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-кардиолог</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 11 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Невролог.png">
              <div class="name">
                <p class="fullname">Бекшина Тамара</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-невролог</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 18 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Уролог.png">
              <div class="name">
                <p class="fullname">Фоменко Александр</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-уролог</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 23 года</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Педиатр.png">
              <div class="name">
                <p class="fullname">Кирикова Анна</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-педиатр</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 12 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Нарколог.png">
              <div class="name">
                <p class="fullname">Куц Евгений</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-нарколог</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 10 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Инфекционист.png">
              <div class="name">
                <p class="fullname">Таргаков Вадим</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-инфекционист</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 24 года</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Реабилитолог.png">
              <div class="name">
                <p class="fullname">Ачилов Алишер</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Врач-реабилитолог</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 10 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Медсестра%201.png">
              <div class="name">
                <p class="fullname">Гурина Наталья</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Медсестра</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 16 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Медсестра.png">
              <div class="name">
                <p class="fullname">Акимова Наталья</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Медсестра</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 10 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Медсестра%203.png">
              <div class="name">
                <p class="fullname">Варий Татьяна</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Медсестра</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 37 лет</span></p>
              </div>
            </div>
            <div class="anketa1">
              <img class="doctor_Uliya"
                   src="/static/img/Медсестра%202.png">
              <div class="name">
                <p class="fullname">Любина Виктория</p>
                <img class="check_mark" src="/static/img/check_mark.svg">
              </div>
              <p class="prof">Медсестра</p>
              <div class="stagh">
                <p class="lets">Стаж:<span class="span__let"> 10 лет</span></p>
              </div>
            </div>
          </div>

        </div>

        <div class="otkliks">
          <div class="otklik">
            <p class="p__kat">Отзывы</p>
            <p class="see__all" @click="goBackToSite">Смотреть все</p>
          </div>
          <video-gallery
              :videos="reviewVideos"
              @video-played="handleVideoPlay"
          />
        </div>


        <div class="bottom">
          <div class="bottom__top">
            <img class="bottom__logo" src="/static/img/logo_white_highres.png">
            <p class="bottom__p"><span class="header__span">Pro</span>MedLine</p>
          </div>
          <div class="bottom__main__text">
            <div class="number">
              <img class="phone1" src="/static/img/Phone.svg">
              <a style = "font-weight: 500" href ="https://dedovagency.ru/backend/phone_redirect?phone=+74958856921" target="_blank" class="bottom__text">+7 (495) 885-69-21</a>
            </div>
            <div class="number">
             <img class="phone1" src="/static/img/Phone(2).svg">
              <p class="bottom__text">Promedline@inbox.ru</p>
            </div>
            <div class="number">
              <img class="phone1" src="/static/img/Phone(1).svg">
              <p class="bottom__text">г. Москва, Севанская улица, 3 к2</p>
            </div>
            <p class="svyaz">Свяжитесь с нами</p>
          </div>
          <div class="bottom__buttons">
            <div class="bottom_mess">
              <button class="teleg" @click="redirectToTelegram">Telegram</button>
              <button class="whats" @click="redirectToWhatsApp">WhatsApp</button>
            </div>
            <button class="back__site" @click="goBackToSite">На основной сайт</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Structure -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <AppCategories @close="closeModal"/>
      </div>
    </div>

  </div>
</template>

<script>
import AppCategories from './AppCategories.vue';
import VideoGallery from './AppVideoGallery.vue';
import {mapActions} from 'vuex'

export default {
  components: {AppCategories, VideoGallery},

  data() {
    return {
      showModal: false,
      categoriesList: [
        {name: 'Терапевт', price: 4990, image: '/static/img/HospitalRoom.png'},
        {name: 'Педиатр', price: 4990, image: '/static/img/Cartoon Boy.png'},
        {name: 'Невролог', price: 4990, image: '/static/img/MentalHealth.png'},
        {name: 'Кардиолог', price: 4990, image: '/static/img/heart.png'},
        {name: 'Инфекционист', price: 4990, image: '/static/img/virus.png'},
        {name: 'Хирург', price: 4990, image: '/static/img/hirurg.png'},
        {name: 'Онколог', price: 4500, image: '/static/img/Cancer Ribbon.png'},
        {name: 'Нарколог', price: 4990, image: '/static/img/med-drop.png'},
        {name: 'Уролог', price: 4990, image: '/static/img/HospitalRoom.png'},
        {name: 'Уколы', price: 4990, image: '/static/img/med-ico.png'},
        {name: 'Установка капельниц', price: 4990, image: '/static/img/Ambulance.svg'},
        {name: 'Хирургия', price: 4990, image: '/static/img/hirurg.png'},
        {name: 'Вызов медсестры', price: 4990, image: '/static/img/med-ico.png'},
        {name: 'Вывод из запоя', price: 4990, image: '/static/img/friend.png'},
        {name: 'IV-Терапия', price: 4990, image: '/static/img/med-ico.png'},
        {name: 'Другие услуги', price: 4990, image: '/static/img/korzina.svg'}
      ]
    };
  },
  computed: {
    // Get only the featured categories we want to show on the home page
    featuredCategories() {
      // Map the categories we want to show with their original index
      const featuredIndexes = [0, 10, 14] // Терапевт, Установка капельниц, IV-Терапия
      return featuredIndexes.map(index => ({
        ...this.categoriesList[index],
        index // Keep track of original index for routing
      }))
    }
  },

  methods: {
    ...mapActions(['selectCategory']),
    handleCategorySelect(index) {
      this.selectCategory(index);
      this.$router.push({
        name: 'catalog',
        params: {
          category: index.toString(),
        }
      });
    },


    redirectToCatalog() {
      this.$router.push('/catalog')
    },
    openModal() {
      this.showModal = true;
    },
    openModal2() {
      this.showModal2 = true;
    },
    closeModal() {
      this.showModal = false;
    },
    closeModal2() {
      this.showModal2 = false;
    },
    goToCategory(index) {
      this.$router.push({
        name: 'catalog',
        params: {category: index.toString()}
      })
    },
    redirectToTelegram() {
      // Open Telegram link in a new tab
      window.open('https://t.me/promedline', '_blank');
    },
    redirectToWhatsApp() {
      // Open WhatsApp link in a new tab
      window.open('https://wa.me/79255578003', '_blank');
    },
    goBackToSite() {
      // Open the site link in a new tab
      window.open('https://promedline.ru', '_blank');
    },
    navigateToItem(id) {
      this.$router.push({name: 'Item', params: {id}})
    },
    async makeOrderRightAway() {
      try {
        const therapistConsultation = {
          id: 1,
          date: "до 24 октября",
          category: 0,
          name: "Консультация терапевта по телефону",
          description: "Консультация\nПодбор терапии\nОформление заявки на выезд врача",
          price: 0,
          old_price: 1490,
          image: "/static/img/Therapy.png"
        };

        await this.$store.dispatch('addToCart', therapistConsultation);

        const userData = this.$store.state.auth.userData;

        if (userData && userData.telegram_user_id) {
          const cartItems = [{
            id: therapistConsultation.id,
            name: therapistConsultation.name,
            price: therapistConsultation.price,
            quantity: 1
          }];

          const orderData = {
            formData: {
              full_name: userData.full_name,
              phone: userData.phone,
              address: userData.address,
              dateOfBirth: userData.dateOfBirth,
              telegram_user_id: userData.telegram_user_id
            },
            cartItems: cartItems
          };

          // Check if contact exists in CRM
          const contactSearchData = {
            filter: {
              "PHONE": userData.phone
            },
            select: ["ID"]
          };

          const contactSearchResponse = await fetch('https://promedline.bitrix24.ru/rest/1/cdq9n2q7cw6uln02/crm.contact.list.json', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(contactSearchData)
          });

          const searchResult = await contactSearchResponse.json();
          let contactId;

          if (searchResult.result && searchResult.result.length > 0) {
            // Contact found
            contactId = searchResult.result[0].ID;
          } else {
            // Contact not found, create a new one
            const contactData = {
              fields: {
                FULL_NAME: userData.full_name,
                NAME: userData.full_name,
                FIRST_NAME: userData.full_name,
                PHONE: [{VALUE: userData.phone, VALUE_TYPE: "MOBILE"}],
                EMAIL: [{VALUE: userData.email, VALUE_TYPE: "WORK"}]
              }
            };

            const contactResponse = await fetch('https://promedline.bitrix24.ru/rest/1/cdq9n2q7cw6uln02/crm.contact.add.json', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(contactData)
            });

            const contactResult = await contactResponse.json();
            if (!contactResponse.ok || !contactResult.result) {
              throw new Error(contactResult.error_description || 'Не удалось создать контакт');
            }

            contactId = contactResult.result;
          }

          // Send order data to custom backend
          const response = await fetch('https://dedovagency.ru/backend/submit-lead', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(orderData)
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Не удалось отправить заказ');
          }


          // Create a deal in CRM linked to the contact
          const dealData = {
            fields: {
              TITLE: `Заказ от ${userData.full_name}`,
              CONTACT_ID: contactId * 1,
              COMMENTS: `$Заявка на вызов врача (по телефону)\n`,
              OPPORTUNITY: therapistConsultation.price,
              CURRENCY_ID: 'RUB',
              SOURCE_ID: "UC_A4BTBJ",
              CATEGORY_ID: 0,

            }
          };

          const crmResponse = await fetch('https://promedline.bitrix24.ru/rest/1/cdq9n2q7cw6uln02/crm.deal.add.json', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(dealData)
          });

          if (!crmResponse.ok) {
            const crmErrorData = await crmResponse.json();
            throw new Error(crmErrorData.error_description || 'Не удалось добавить сделку в CRM');
          }

          // Clear cart and redirect to success page
          await this.$store.dispatch('clearCart');
          this.$router.push('/OneMoment');
        } else {
          // If not authenticated, redirect to form
          this.$router.push({name: 'formOrder'});
        }
      } catch (error) {
        console.error('Error processing order:', error);
        // Optionally, show an error message to the user
      }
    }
    ,

  },
};
</script>
<style scoped>
.screen {
  padding: 20px;
  padding-top: 0.1vh;
  padding-bottom: 80px;
}

.background {
  padding-bottom: 5vh;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 100vw;
  width: 100%;
}

.otkliks {
  margin: 2rem 0;
}

.otklik {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}


/* Add your styles here */
</style>
