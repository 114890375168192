<template>
  <div>
    <div v-if="isLoading" class="skeleton-loader">
      <div class="skeleton-item"></div>
    </div>

    <template v-else>
      <AppBonus
        v-if="!userData"
        @userSaved="handleUserSaved"
      />
      <AppPromotionsDay
        v-else
        :userData="userData"
      />
    </template>
  </div>
</template>

<script>
import AppBonus from './AppBonus.vue';
import AppPromotionsDay from './AppPromotionsDay.vue';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['userData', 'isLoading'])
  },
  components: {
    AppBonus,
    AppPromotionsDay
  },
  methods: {
    async handleUserSaved(userData) {
      try {
        await this.$store.dispatch('auth/updateUserData', userData);
      } catch (error) {
        console.error('Failed to save user data:', error);
      }
    }
  }
};
</script>