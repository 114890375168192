<template>
  <div class="mini-apps_centre">
    <div style="display: flex; margin-top: 30px;">
      <p style="margin-left: 5vw; font-weight: 600; font-size: 17px;">Заказы</p>
      <img class="cross1" src="/static/img/cross.svg" @click="goBack">
    </div>

    <ul>
      <li v-for="order in orders" :key="order.id">
        <div class="item-order" @click="viewOrderDetails(order.id)">
          <div class="info__item-order">
            <div style="display: flex;">
              <p class="number-order">Заказ №{{ order.number }}</p>
              <p class="status-order">{{ order.status }}</p>
            </div>
            <p class="date-create-order">Дата создания: {{ order.date }}</p>
            <p class="total-price-order">Сумма: {{ order.total }}</p>
          </div>
          <button><img src="/static/img/chevron_right_16.svg"></button>
        </div>
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  props: ['userData'],
  data() {
    return {
      orders: [1,2,3,4,5,6,7,8],
      showCopyNotification: false, // Controls visibility of the copy notification
      refCode: this.generateRefCode() // Генерация случайного ref-кода
    };
  },

  methods: {
    viewOrderDetails(orderId) {
      this.$router.push(`/order/${orderId}`);
    },
    generateRefCode() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    goBack() {
      this.$router.push('/friends');
    },
    editAccount() {
      localStorage.removeItem('userData');
      location.reload();
    },
    copyInviteLink() {
      // Текст для копирования
      const inviteLink = `https://t.me/promedline?start=ref${this.refCode}`;

      // Копирование текста в буфер обмена
      navigator.clipboard.writeText(inviteLink)
          .then(() => {
            this.showCopyNotification = true;
            setTimeout(() => {
              this.showCopyNotification = false;
            }, 2000); // Скрыть уведомление через 2 секунды
          })
          .catch(err => {
            console.error('Ошибка при копировании:', err);
          });
    }
  }
};
</script>

<style scoped>
ul, li {
  padding-left: 1vw;
  list-style-type: none;
}
.mini-apps_centre{
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 10vh;
}

.cross1{
  background-color: #0000000A;
  padding: 5px;
  margin-left: auto;
  cursor: pointer;
}


</style>