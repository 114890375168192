<template>
  <div class="formAccount">
    <div class="pop-up-bar">
      <p>Ваш аккаунт</p>
      <button @click="$emit('close')">
        <img src="/static/img/cross.svg" alt="Close">
      </button>
    </div>

    <form @submit.prevent="saveChanges">
      <div class="input_data">
        <p>ФИО</p>
        <input
          v-model="formData.full_name"
          placeholder="Иванов Иван Иванович"
          required
        />
      </div>

      <div class="input_data">
        <p>ТЕЛЕФОН</p>
        <input
          v-model="formData.phone"
          placeholder="+7 999 999 99 99"
          required
        />
      </div>

      <div class="input_data">
        <p>АДРЕС</p>
        <input
          v-model="formData.address"
          placeholder="г. Москва, ул. Лобачевского, д.8"
        />
      </div>

      <div class="input_data">
        <p>ДАТА РОЖДЕНИЯ</p>
        <input
          v-model="formData.birth_date"
          type="date"
        />
      </div>

      <button type="submit" class="submit-order-btn2">Сохранить</button>

      <label class="container">
        Согласен(на) на обработку персональных данных
        <input
          type="checkbox"
          v-model="formData.acceptPersonalData"
          required
        />
        <span class="checkmark"></span>
      </label>

      <label class="container">
        Согласен(на) с <a href="#">политикой конфеденциальности</a>
        <input
          type="checkbox"
          v-model="formData.acceptPrivacyPolicy"
          required
        />
        <span class="checkmark"></span>
      </label>
    </form>
  </div>
</template>

<script>
export default {
  name: 'AppFormAccount',
  props: {
    initialUserData: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        phone: this.initialUserData?.phone || '',
        full_name: this.initialUserData?.full_name || '',
        address: this.initialUserData?.address || '',
        birth_date: this.initialUserData?.birth_date || '',
        acceptPersonalData: false,
        acceptPrivacyPolicy: false
      }
    };
  },
  methods: {
    async saveChanges() {
  const telegramUserId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id;

  if (!telegramUserId) {
    console.error('No Telegram user ID available');
    alert('Произошла ошибка при получении Telegram ID.');
    return;
  }

  try {
    const response = await fetch('https://dedovagency.ru/backend/api/user/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        telegramData: {
          id: telegramUserId
        },
        userData: {
          name: this.formData.full_name,
          phone: this.formData.phone,
          address: this.formData.address,
          birthdate: this.formData.birth_date
        }
      })
    });

    if (!response.ok) {
      throw new Error('Failed to update profile');
    }

    const updatedData = await response.json();
    this.$emit('user-saved', updatedData);
    this.$emit('close');
    window.location.reload();
  } catch (error) {
    console.error('Error updating profile:', error);
    alert('Произошла ошибка при сохранении данных. Пожалуйста, попробуйте позже.');
  }
}
  },
  created() {
    console.log('Initial user data received:', this.initialUserData);
  }
};
</script>

<style scoped>
.formAccount {
  //height: 96vh;
  padding-bottom: 20vh;
}

.submit-order-btn2 {
   background-color: #43d5ca;
    color: #fff;
    /* width: 75vw; */
   /* width: 100%;*/
    margin-top: 10px;
    /* padding: 1.4rem 0rem; */
    border-radius: 12px;
    font-size: 14px;
    font-weight: 700;
    border: 0 solid #000;
    height: 42px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}
</style>
