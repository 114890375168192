<!-- pro-med-line/src/App.vue -->
<template>
  <div id="app">
    <AppHeader />
    <router-view />
    <AppMenu />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppMenu from './components/AppMenu.vue'

export default {
  created() {
    this.$store.dispatch('auth/initializeAuth');
  },
  name: 'App',
  components: {
    AppHeader,
    AppMenu
  }
}
</script>

<style>
@import '../public/static/css/style.css';
@import '../public/static/css/reset.css';

/* Add your global styles here */
</style>
