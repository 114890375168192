// store/auth.js
export default {
  namespaced: true,

  state: {
    userData: null,
    isAuthenticated: false,
    isLoading: true,
    telegramWebapp: null
  },

  mutations: {
    SET_USER_DATA(state, data) {
      state.userData = data;
      state.isAuthenticated = !!data;
    },
    SET_LOADING(state, status) {
      state.isLoading = status;
    },
    SET_TELEGRAM_WEBAPP(state, webapp) {
      state.telegramWebapp = webapp;
    }
  },

  actions: {
    // Removed unused state parameter
    async initializeAuth({ commit, dispatch }) {
      commit('SET_LOADING', true);

      // Check cached auth first
      const cached = localStorage.getItem('telegram_auth');
      if (cached) {
        try {
          const { data, timestamp } = JSON.parse(cached);
          const isExpired = Date.now() - timestamp > 1000 * 60 * 30; // 30 minutes

          if (!isExpired) {
            commit('SET_USER_DATA', data);
            commit('SET_LOADING', false);
            // Refresh in background
            dispatch('refreshAuth');
            return;
          }
        } catch (error) {
          console.error('Cache parse error:', error);
        }
      }

      // No valid cache, do fresh auth
      await dispatch('refreshAuth');
    },

    // Removed unused state parameter
    async refreshAuth({ commit }) {
      try {
        const webapp = window.Telegram.WebApp;
        commit('SET_TELEGRAM_WEBAPP', webapp);

        if (!webapp?.initDataUnsafe?.user) {
          commit('SET_USER_DATA', null);
          commit('SET_LOADING', false);
          return;
        }

        const response = await fetch('https://dedovagency.ru/backend/api/user/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            telegramData: webapp.initDataUnsafe.user
          })
        });

        if (response.ok) {
          const data = await response.json();
          commit('SET_USER_DATA', data);

          // Cache the auth data
          localStorage.setItem('telegram_auth', JSON.stringify({
            data,
            timestamp: Date.now()
          }));
        }
      } catch (error) {
        console.error('Auth error:', error);
        commit('SET_USER_DATA', null);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async updateUserData({ commit, state }, userData) {
      try {
        const response = await fetch('https://dedovagency.ru/backend/api/user/update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            telegramData: state.telegramWebapp.initDataUnsafe.user,
            userData: userData
          })
        });

        if (response.ok) {
          const data = await response.json();
          commit('SET_USER_DATA', data.userData);

          // Update cache
          localStorage.setItem('telegram_auth', JSON.stringify({
            data: data.userData,
            timestamp: Date.now()
          }));

          return data.userData;
        }
      } catch (error) {
        console.error('Update error:', error);
        throw error;
      }
    }
  }
};