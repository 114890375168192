<template>
  <div class="cart-container">
    <img class="friend" src="/static/img/friend.png" alt="Empty Cart">

    <p class="friend-label">Приглашайте друзей</p>
    <p class="friend-tip">Приведите друга и получите 10 000 медкоинов (10 медкоинов = 1 рубль)!</p>
    <div class="balance-widget">
      <p class="label__balance-widget">Баланс медкоинов</p>
      <p class="balance__balance-widget">0 <b>М</b></p>
      <div>
        <button class="btn-invite-friend">
          <a :href="telegramShareUrl" class="tgme_widget_share_btn" target="_blank">
            <i class="tgme_widget_share_icon"></i>
            <span class="tgme_widget_share_label">Пригласить друзей</span>
          </a>
        </button>
        <button class="clipboard-invite-link" @click="copyInviteLink">
          <img src="/static/img/clipboard-icon.png" alt="Copy">
        </button>
        <div v-if="showCopyNotification" class="copy-notification">
          Скопирована пригласительная ссылка!
        </div>
      </div>
    </div>

    <h2 class="label text-xl font-semibold mb-4">Корзина ({{ cartItems.length }})</h2>


    <!-- Empty Cart State -->
    <div v-if="cartItems.length === 0" class="empty-cart">
      <img class="bin-on-wheels-empty w-32 h-32 mx-auto mb-4"
           src="/static/img/bin-on-wheels-empty.png"
           alt="Empty Cart">
      <p class="status-bin text-lg font-medium text-center mb-2">
        Ваша корзина пуста
      </p>
      <p class="bin-tip text-sm text-gray-500 text-center">
        Переходите в каталог и вызывайте врача на дом выгодно
      </p>
    </div>

    <!-- Filled Cart State -->
    <div v-else class="filled-cart">
      <!-- Cart Items List -->
      <div v-for="item in cartItemsWithDiscounts"
           :key="item.id"
           class="cart-item bg-white rounded-lg p-4 mb-3 flex items-center">
        <img :src="item.image"
             :alt="item.name"
             class="icon__cart-item w-12 h-12 mr-4">

        <div class="flex-grow">
          <p class="name__cart-item font-medium">{{ item.name }}</p>
          <div class="flex items-center">
            <template v-if="item.discount">
              <del class="text-sm text-gray-400 mr-2">{{ item.originalPrice }} ₽</del>
              <p class="price__cart-item text-teal-400 font-bold">
                {{ item.discountedPrice }} ₽
                <span class="text-sm ml-1">{{ formatDiscount(item.discount) }}</span>
              </p>
            </template>
            <template v-else>
              <p class="price__cart-item text-teal-400 font-bold">
                {{ item.price }} ₽
              </p>
            </template>
          </div>
        </div>

        <button class="delete-btn" @click="removeFromCart(item)">
          <svg fill="#797979" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 461.363 461.363"
               xml:space="preserve">
  <g id="SVGRepo_bgCarrier" stroke-width="0"/>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
            <g id="SVGRepo_iconCarrier">
    <g>
      <g>
        <polygon points="95.088,461.363 366.276,461.363 389.653,105.984 71.71,105.984 "/>
        <path
            d="M279.192,0h-97.02L71.71,23.557v50.83h317.943v-50.83L279.192,0z M301.683,47.871h-142V26.516h142V47.871z"/>
      </g>
    </g>
  </g>
</svg>
        </button>
      </div>

      <!-- Total Section -->
      <div class="total-section bg-white rounded-lg p-4 mt-4">
        <div class="flex justify-between mb-2">
          <p class="font-bold total-label">ИТОГО</p>
        </div>

        <div class="flex justify-between text-sm mb-2">
          <p class="text-gray-500">Скидки</p>
          <p class="text-teal-400">-{{ totalDiscounts }} ₽</p>
        </div>

        <div class="flex justify-between text-sm mb-4">
          <p class="text-gray-500">Бонусы</p>
          <p class="text-teal-400">-{{ bonusAmount }} ₽</p>
        </div>

        <hr>

        <div class="border-t pt-4">
          <p class="text-gray-400 line-through">{{ subtotal }} ₽</p>
          <p class="text-xl total-sum font-bold text-right">
            {{ cartTotal }} ₽
          </p>
        </div>
      </div>

      <!-- Error Message -->
      <div v-if="error" class="error-message mt-4 p-4 bg-red-100 text-red-700 rounded-lg">
        {{ error }}
      </div>

      <!-- Order Button -->
      <button
          @click="startOrderProcess"
          :disabled="isSubmitting"
          class="submit-order-btn2"
      >
        {{ isSubmitting ? 'Отправка заказа...' : 'Оформить заказ' }}
      </button>
    </div>

    <div v-if="showOneMoment" class="modal-container">
      <button class="close-btn" @click="closeOneMoment">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>

      <div class="icon-container">
        <img src="/static/img/cart_svg.svg" alt="Cart Icon" class="w-16 h-16">
      </div>

      <h2 class="modal-header">Один момент</h2>

      <p class="modal-text">
        Цены на услуги зависят от района в Москве и Московской области.
      </p>

      <div class="inner-box">
        <p>Мы свяжемся с Вами и согласуем актуальную цену и возможные изменения в заказе.</p>
      </div>

      <button class="confirm-btn" @click="confirmAndSubmitOrder">
        Хорошо
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CartComponent',

  data() {
    return {
      showCopyNotification: false,
      refCode: this.generateRefCode()
    };
  },

  setup() {
    const isSubmitting = ref(false);
    const error = ref(null);
    const showOneMoment = ref(false);

    return {
      isSubmitting,
      error,
      showOneMoment
    };
  },

  computed: {
    ...mapState('auth', ['userData', 'isLoading']),
    ...mapGetters([
      'cartItemsWithDiscounts',
      'subtotal',
      'totalDiscounts',
      'bonusAmount',
      'cartTotal'
    ]),
    userDataWithTelegram() {
      const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id;
      return {
        ...this.userData,
        telegram_user_id: this.userData?.telegram_user_id || telegramId || null
      };
    },
    telegramShareUrl() {
      const telegramId = this.userDataWithTelegram.telegram_user_id || '';
      return `https://t.me/share/url?url=https://t.me/promedline_bot?start=${telegramId}&text=Привет! Promedline в Москве и МО - вызов врача на дом.`;
    },
    cartItems() {
      return this.$store.getters.cart;
    }
  },

  methods: {
    formatDiscount(discount) {
      if (discount.type === 'percentage') {
        return `-${discount.value}%`;
      }
      return `-${discount.value} ₽`;
    },

    removeFromCart(item) {
      this.$store.dispatch('removeFromCart', item);
    },

    startOrderProcess() {
      if (!this.userData || !this.userData.telegram_user_id) {
        this.$router.push({ name: 'formOrder' });
        return;
      }
      this.showOneMoment = true;
    },

    closeOneMoment() {
      this.showOneMoment = false;
    },

    async confirmAndSubmitOrder() {
      if (this.isSubmitting) return;

      try {
        this.isSubmitting = true;
        this.error = null;

        const cartItems = this.cartItems.map(item => ({
          id: item.id,
          name: item.name,
          price: this.$store.getters.getDiscountedPrice(item),
          quantity: item.quantity
        }));

        const orderDetails = cartItems.map(item =>
          `${item.name} - ${item.quantity} шт. по ${item.price} ₽`
        ).join('\n');
        const totalPrice = `Общая сумма: ${this.cartTotal} ₽`;

        // Check for contact existence by phone
        const contactSearchData = {
          filter: {
            "PHONE": this.userData.phone
          },
          select: ["ID"]
        };

        const searchResponse = await fetch('https://promedline.bitrix24.ru/rest/1/cdq9n2q7cw6uln02/crm.contact.list.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(contactSearchData)
        });

        const searchResult = await searchResponse.json();
        let contactId;

        if (searchResult.result && searchResult.result.length > 0) {
          // Contact found
          contactId = searchResult.result[0].ID;
        } else {
          // Create a new contact
          const contactData = {
            fields: {
              FULL_NAME: this.userData.full_name,
              NAME: this.userData.full_name,
              FIRST_NAME: this.userData.full_name,
              PHONE: [{ VALUE: this.userData.phone, VALUE_TYPE: "MOBILE" }],
              EMAIL: [{ VALUE: this.userData.email, VALUE_TYPE: "WORK" }]
            }
          };

          const contactResponse = await fetch('https://promedline.bitrix24.ru/rest/1/cdq9n2q7cw6uln02/crm.contact.add.json', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(contactData)
          });

          const contactResult = await contactResponse.json();
          if (!contactResponse.ok || !contactResult.result) {
            throw new Error(contactResult.error_description || 'Не удалось создать контакт');
          }

          contactId = contactResult.result;
        }

        // Create a deal with the CONTACT_ID
        const dealData = {
          fields: {
            TITLE: `Заказ от ${this.userData.full_name}`,
            CONTACT_ID: contactId * 1,
            COMMENTS: `${orderDetails}\n${totalPrice}`,
            OPPORTUNITY: this.cartTotal,
            CURRENCY_ID: 'RUB',
            SOURCE_ID: "UC_A4BTBJ",
            CATEGORY_ID: 0,
          }
        };

        const crmResponse = await fetch('https://promedline.bitrix24.ru/rest/1/cdq9n2q7cw6uln02/crm.deal.add.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dealData)
        });

        if (!crmResponse.ok) {
          const errorData = await crmResponse.json();
          throw new Error(errorData.error_description || 'Не удалось создать сделку');
        }

        await this.$store.dispatch('clearCart');
        this.$router.push('/thankyou');
      } catch (error) {
        console.error('Error submitting order:', error);
        this.error = error.message || 'Произошла ошибка при оформлении заказа. Пожалуйста, попробуйте еще раз.';
        this.showOneMoment = false;
      } finally {
        this.isSubmitting = false;
      }
    },

    async copyInviteLink() {
      const telegramId = this.userDataWithTelegram.telegram_user_id || '';
      const inviteLink = `https://t.me/promedline?start=${telegramId}`;
      try {
        await navigator.clipboard.writeText(inviteLink);
        this.showCopyNotification = true;
        setTimeout(() => {
          this.showCopyNotification = false;
        }, 2000);
      } catch (err) {
        console.error('Ошибка при копировании:', err);
      }
    },

    generateRefCode() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return Array.from({ length: 10 }, () =>
        characters.charAt(Math.floor(Math.random() * characters.length))
      ).join('');
    },
  },

  watch: {
    cartItems: {
      handler(newItems) {
        if (newItems.length === 0) {
          this.$router.push({ name: 'catalog' });
        }
      },
      deep: true
    }
  }
};
</script>


<style scoped>
.header__bottom {
  bottom: 100px;
}

.cart, .cart-container {
  padding: 20px;
  padding-bottom: 100px
}

.bottom-div__item-promotion > p {
  line-height: 1.0;
  font-size: 15px;
}

.bottom-div__item-promotion {
  padding: 10px;
}

.oldPrice {
  font-size: 12px;
  color: #707579;
  opacity: 40%;
  line-height: 0.5;
}

.newPrice {
  color: #54D9CF;
  font-size: 14px;
  font-weight: 800;
  line-height: 0.5;
}

.item-promotion {
  margin: 10px 0;
}

.old-price-and-new {
  display: flex;
  justify-content: top;
  align-items: center;
}

.all-promotions {
  margin-top: 20px;
}

/* OneMoment Dialog Styles */
.mini-apps_centre {
  width: 90%;
  max-width: 400px;
}

.cart_svg {
  background-color: #FEFEFE;
  border-radius: 50%;
  padding: 10px;
}

.one_moment {
  font-size: 28px;
  font-weight: 600;
}

.mini_p, .back-mini_p {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
}

.back-mini_p {
  background-color: #ffffff;
  padding: 10px 16px;
  border-radius: 12px;
}

.great_but {
  background-color: #43D5CA;
  color: #FFFFFF;
  border: 0;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 14px;
  border-radius: 12px;
  width: 100%;
}

/* Container styling */
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 20px;
}

/* Close button styling */
.close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Icon styling */
.icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

/* Header text */
.modal-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}

/* Description text */
.modal-text {
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}

/* Inner content box styling */
.inner-box {
  background-color: #f8f8f8;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
}

/* Button styling */
.confirm-btn {
  display: block;
  width: 100%;
  background-color: #43D5CA;
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

.confirm-btn:hover {
  background-color: #43D5CA;
}

</style>
<style scoped>
/* Add specific styles for the friends component */
.friends {
  padding: 20px;
  padding-bottom: 100px;
}

ul, li {
  padding-left: 1vw;
  list-style-type: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  z-index: 1234;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.copy-notification {
  margin-top: 10px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.submit-order-btn2 {
   background-color: #43d5ca;
    color: #fff;
    /* width: 75vw; */
   /* width: 100%;*/
    margin-top: 10px;
    /* padding: 1.4rem 0rem; */
    border-radius: 12px;
    font-size: 14px;
    font-weight: 700;
    border: 0 solid #000;
    height: 42px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

</style>