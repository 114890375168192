<script setup>

</script>

<template>
  <div class="mini-apps_centre">
    <div class="description_view">
      <div class="img_fake">
        <div class="circle1"></div>
        <img class="heart" src="/static/img/Doctors%20Bag.svg">
        <div class="circle2"></div>
      </div>
      <div class="content_desc">
        <p>Капельница при<br> ОРВИ и гриппе</p>
        <p class="old-price__cart-item">4990 ₽</p>
        <p class="new__price">2 990 ₽</p>
        <button class="add_in_cart">Добавить в корзину</button>
      </div>


    </div>
    <p class="mini_p">Описание</p>
    <ul>
      <li>Выезд кардиолога</li>
      <li>Осмотр</li>
      <li>Постановка диагноза + капельницы</li>
      <li>Разработка плана лечения/обследования</li>
    </ul>
    <button class="great_but">Добавить в корзину</button>
  </div>
</template>

<style scoped>
.add_in_cart{
  margin-top: 1vh;
  min-width: 34px;
  padding: 8px 12px 8px 12px;
  gap: 6px;
  border-radius: 20px;
  opacity: 0px;
  border: 0;
  background-color:#43D5CA;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;


}

.content_desc{
  margin-left: 15%;
  margin-top: 1vh;

}

.new__price{
  font-size: 15px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #43D5CA;

}

.description_view{
  background-color: #FFFFFF;
  border-radius: 10px;
  display: flex;
}

.heart{
  margin-left: 20%;
}

.circle1{
  background-color: #43D5CA;
  width: 40px;
  height: 40px;
  border-radius: 15px 3px 100px 3px;
}

.circle2{
  background-color: #43D5CA;
  width: 40px;
  height: 40px;
  border-radius: 100px 0px 0px 0px;
  transform: translate(150%,0);
}

.mini-apps_centre{
  margin-top: 15vh;
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
}



.mini_p{
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.07999999821186066px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #707579;
  margin-left: 4%;
  margin-top: 3vh;
}

.back-mini_p{
  background-color: #ffffff;
  padding: 10px 16px 10px 13px;
  gap: 5px;
  border-radius: 12px;
  opacity: 0px;
  margin-top:1vh;
  transform: translate(-50%,0);
  margin-left: 50%;
  width: 90vw;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.great_but{
  min-width: 42px;
  padding: 12px 14px 12px 14px;
  gap: 8px;
  border-radius: 12px;
  opacity: 0px;
  background-color: #43D5CA;
  color: #FFFFFF;
  border:0;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 2vh;
  transform: translate(-50%,0);
  margin-left: 50%;
  width: 90vw;
}
</style>