// pro-med-line/src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import AppCart from '@/components/AppCart.vue'
import AppHome from '@/components/AppHome.vue'
import AppCatalog from '@/components/AppCatalog'
//import AppFormOrder from '@/components/AppFormOrder'
import MoreOrder from "@/components/MoreOrder.vue";
import OneMoment from '@/components/OneMoment.vue'
import AppThank from '@/components/AppThank.vue'
import AccountManager from '@/components/AccountManager'
import PromotionsManager from '@/components/PromotionsManager'
import AppSlider from '@/components/AppSlider.vue'
import AppDescription from "@/components/AppDescription.vue"
import NumberOrder from "@/components/NumberOrder.vue";
import OrderManager from "@/components/OrderManager.vue";
import AppCategories from "@/components/AppCategories.vue";
import ItemPage from "@/components/ItemPage.vue"
import prom from "@/components/AppPromotionsDay.vue"
const routes = [
  {
    path: '/',
    name: 'Main',
    component: AppHome // Main page as the index route
  },
  {
    path: '/cart',
    name: 'Cart',
    component: AppCart
  },
  {
    path: '/promotions',
    name: 'PromotionsDay',
    component: PromotionsManager
  },
  {
    path: '/catalog/:showCategoriesPopup?',
    name: 'catalog',
    component: AppCatalog,
    props: true
  },
  {
    path: '/friends',
    name: 'AccountManager',
    component: AccountManager
  },
  {
    path: '/formOrder',
    name: 'formOrder',
    component: OrderManager
  },
  {
    path: '/OneMoment',
    name: 'OneMoment',
    component:  OneMoment
  },
  {
    path: '/thankyou',
    name: 'AppThank',
    component: AppThank
  },
  {
    path: '/Slider',
    name: 'AppSlider',
    component: AppSlider
  },
  {
    path: '/description',
    name: 'AppDescription',
    component: AppDescription
  },
  { path: '/item/:id',
   name: 'Item',
    component: ItemPage
  },
  {
    path: '/order/:id',
    name: 'NumberOrder',
    component: NumberOrder
  },
  {
    path: '/MoreOrder',
    name: 'MoreOrder',
    component: MoreOrder
  },
  {
    path: '/Categories',
    name: 'categories',
    component: AppCategories
  },
  {
    path: '/prom',
    name: 'prom',
    component: prom
  },


]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
