<template>
  <div class="mini-apps_centre">
    <img class="galka" src="/static/img/Login(1).svg">
    <p class="one_moment">Спасибо что<br> доверились нам!</p>
    <p class="back-mini_p">Скоро мы с Вами свяжемся!</p>
    <button class="great_but" @click=navigateToFriends>К заказам</button>
  </div>
</template>

<script>
export default {
  methods: {
    navigateToFriends() {
      this.$router.push('/friends');
    }
  }
}
</script>


<style scoped>
.mini-apps_centre{
  margin-top: 15vh;
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.galka{
  width: 90px;
  height: 90px;
  gap: 0px;

  transform: translate(-50%,0);
  margin-left: 50%;
  background-color: #FEFEFE;
  border-radius: 50%;
  padding: 18px;
}

.one_moment{
  margin-top: 2vh;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.back-mini_p{
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: #ffffff;
  width: Fill (302px)px;
  height: Hug (80px)px;
  padding: 10px 16px 10px 16px;
  gap: 5px;
  border-radius: 12px;

  margin-top:1vh;
  transform: translate(-50%,0);
  margin-left: 50%;
  width: 80vw;
}

.great_but{
  min-width: 42px;
  padding: 12px 14px 12px 14px;
  gap: 8px;
  border-radius: 12px;

  background-color: #43D5CA;
  color: #FFFFFF;
  border:0;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 2vh;
  transform: translate(-50%,0);
  margin-left: 50%;
  width: 80vw;
}
</style>