<template>
  <header>
    <div class="header__top">
      <router-link to="/">
        <img class="header__logo" src="/static/img/logo.JPG" alt="Logo" height="25px">
        <p class="header__p"><span class="header__span">Pro</span>MedLine</p>
      </router-link>
      <div class="korzina_svg">
        <router-link to="/friends">
          <img src="/static/img/profileicon.png" width="25" height="25">
        </router-link>
      </div>
    </div>
    <div class="input-container">
      <svg class="lupa__svg" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M9.88427 8.82197C11.5219 6.66636 11.3568 3.57862 9.38909 1.61091C7.2412 -0.536971 3.7588 -0.536971 1.61091 1.61091C-0.536971 3.7588 -0.536971 7.2412 1.61091 9.38909C3.57887 11.357 6.66712 11.5219 8.82277 9.88366L12.7669 13.8275L12.8437 13.8947C13.1376 14.1184 13.559 14.096 13.8275 13.8275C14.1204 13.5346 14.1204 13.0598 13.8275 12.7669L9.88427 8.82197ZM2.31802 2.31802C4.07538 0.56066 6.92462 0.56066 8.68198 2.31802C10.4393 4.07538 10.4393 6.92462 8.68198 8.68198C6.92462 10.4393 4.07538 10.4393 2.31802 8.68198C0.56066 6.92462 0.56066 4.07538 2.31802 2.31802Z"
              fill="#9DBFBE"/>
      </svg>
      <input
          type="text"
          placeholder="Найти услугу или акцию"
          v-model="searchQuery"
          @keyup="handleSearch"
      />
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      searchQuery: '',
      telegram: window.Telegram.WebApp,
    };
  },
  mounted() {
    // Initialize Telegram Web App
    this.telegram.ready();

    // Enable closing confirmation
    this.telegram.enableClosingConfirmation();

    // Handle expand animation on ready
    this.handleExpand();
  },
  methods: {
    handleSearch() {
      if (this.searchQuery.trim()) {
        // First update the Vuex store
        this.$store.dispatch('setSearchTerm', this.searchQuery.trim());

        // Then navigate to the catalog page with the search query
        this.$router.push({
          name: 'catalog',
          query: {search: this.searchQuery.trim()}
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },

    handleExpand() {
      // Start with viewport height at minimum
      this.telegram.expand();

      // Smooth expansion animation
      setTimeout(() => {
        this.isExpanded = true;
        this.telegram.expand();
      }, 100);
    },


    async handleClose() {
      try {
        // Show native Telegram closing confirmation
        const willClose = await this.telegram.showConfirm(
            "Are you sure you want to close the app?"
        );

        if (willClose) {
          // Additional cleanup if needed
          this.telegram.close();
        }
      } catch (err) {
        console.error('Error handling close:', err);
      }
    }


  },

  beforeUnmount() {  // Changed from beforeDestroy to beforeUnmount
    // Cleanup
    this.telegram.disableClosingConfirmation();
  }
};
</script>

<style scoped>
.header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.input-container {
  position: relative;
  margin: 0 1rem;
}

.input-container input {
  width: 100%;
  padding: 0.5rem 2rem;
  //border: 1px solid #9DBFBE;
  border-radius: 4px;
  //font-size: 14px;
}

/*



*/

.lupa__svg {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.korzina_svg {
  display: flex;
  align-items: center;
}

.korzina_svg img {
  width: 24px;
  height: 24px;
}
</style>