<template>
  <div class="categories">
    <div v-if="!showSubcategories">
      <div class="pop-up-bar">
        <p>Категории</p>
        <button @click="$emit('close')"><img src="/static/img/cross.svg"></button>
      </div>
      <div class="list-categories">
        <div
            class="item-categorie"
            @click="showDoctorCallSubcategories"
            style="cursor: pointer;"
        >
          <img class="icon-item__categorie" src="/static/img/HospitalRoom.png">
          <p class="name-item__categorie">Вызов врача</p>
          <p class="price-item__categorie">от 4990 ₽</p>
        </div>
        <router-link
            v-for="category in remainingCategories"
            :key="category.id"
            :to="{ name: 'catalog', params: { category: category.id.toString() }}"
            class="item-categorie"
            @click="handleCategorySelect(category.id)"
        >
          <img class="icon-item__categorie" :src="category.image">
          <p class="name-item__categorie">{{ category.name }}</p>
          <p class="price-item__categorie">от {{ category.price }} ₽</p>
        </router-link>
      </div>
    </div>
    <div v-else>
      <div class="pop-up-bar">
        <p>Выбор врача</p>
        <button @click="closeSubcategories"><img src="/static/img/cross.svg"></button>
      </div>
      <div class="list-categories">
        <router-link
            v-for="category in doctorCallCategories"
            :key="category.id"
            :to="{ name: 'catalog', params: { category: category.id.toString() }}"
            class="item-categorie"
            @click="handleSubcategorySelect(category.id)"
        >
          <img class="icon-item__categorie" :src="category.image">
          <p class="name-item__categorie">{{ category.name }}</p>
          <p class="price-item__categorie">от {{ category.price }} ₽</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'AppCategories',
  data() {
    return {
      showSubcategories: false,
      doctorCallCategories: [
        {id: 0, name: 'Терапевт', price: 4990, image: '/static/img/HospitalRoom.png'},
        {id: 1, name: 'Педиатр', price: 4990, image: '/static/img/Cartoon Boy.png'},
        {id: 2, name: 'Невролог', price: 4990, image: '/static/img/MentalHealth.png'},
        {id: 3, name: 'Кардиолог', price: 4990, image: '/static/img/heart.png'},
        {id: 4, name: 'Инфекционист', price: 4990, image: '/static/img/virus.png'},
        {id: 5, name: 'Хирург', price: 4990, image: '/static/img/hirurg.png'},
        {id: 6, name: 'Онколог', price: 4990, image: '/static/img/Cancer Ribbon.png'},
        {id: 7, name: 'Нарколог', price: 4990, image: '/static/img/med-drop.png'},
        {id: 8, name: 'Уролог', price: 4990, image: '/static/img/HospitalRoom.png'},
      ],
      remainingCategories: [
        {id: 14, name: 'IV-Терапия', price: 4990, image: '/static/img/med-ico.png'},
        {id: 10, name: 'Установка капельниц', price: 4990, image: '/static/img/Ambulance.svg'},
        {id: 13, name: 'Вывод из запоя', price: 4990, image: '/static/img/friend.png'},
        {id: 11, name: 'Хирургия', price: 4990, image: '/static/img/hirurg.png'},
        {id: 12, name: 'Вызов медсестры', price: 4990, image: '/static/img/med-ico.png'},
        {id: 9, name: 'Уколы', price: 4990, image: '/static/img/med-ico.png'},

        {id: 15, name: 'Другие услуги', price: 4990, image: '/static/img/korzina.svg'}
      ]
    }
  },
  methods: {
    ...mapActions(['selectCategory']),
    handleCategorySelect(id) {
      this.selectCategory(id)
      this.$emit('close')
    },
    handleSubcategorySelect(id) {
      this.selectCategory(id)
      this.$emit('close')  // Закрываем весь попап при выборе подкатегории
    },
    showDoctorCallSubcategories() {
      this.showSubcategories = true
    },
    closeSubcategories() {
      this.showSubcategories = false
    }
  }
}
</script>

<style scoped>
.screen {
  margin-left: 2vh;
  margin-bottom: 15vh;
}
</style>