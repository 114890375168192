<template>
  <div class="mini-apps_centre">
    <img class="cart_svg" src="/static/img/cart_svg.svg">
    <p class="one_moment">Один момент</p>
    <p class="mini_p">Цены на услуги зависят от района в<br> Москве и Московской области.</p>
    <p class="back-mini_p">Мы свяжемся с Вами и согласуем<br> актуальную цену и возможные<br> изменения в заказе.</p>
    <button class="great_but" @click=continueBuying>Хорошо</button>
  </div>
</template>
<script>
export default {
  methods: {
    continueBuying() {
      this.$router.push('/thankyou');
    }
  }
}
</script>
<style scoped>
.mini-apps_centre{
  margin-top: 15vh;
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.cart_svg{
  width: 60px;
  height: 60px;
  gap: 0px;

  transform: translate(-50%,0);
  margin-left: 50%;
  background-color: #FEFEFE;
  border-radius: 50%;
  padding: 10px;
  padding-left: 8px;
}

.one_moment{
  margin-top: 2vh;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.mini_p{
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.back-mini_p{
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: #ffffff;
  width: Fill (302px)px;
  height: Hug (80px)px;
  padding: 10px 16px 10px 16px;
  gap: 5px;
  border-radius: 12px;

  margin-top:3vh;
  transform: translate(-50%,0);
  margin-left: 50%;
  width: 80vw;
}

.great_but{
  min-width: 42px;
  padding: 12px 14px 12px 14px;
  gap: 8px;
  border-radius: 12px;

  background-color: #43D5CA;
  color: #FFFFFF;
  border:0;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 2vh;
  transform: translate(-50%,0);
  margin-left: 50%;
  width: 80vw;
}
</style>