<template>
  <div class="formOrder">
    <img class="friend" src="/static/img/friend.png" alt="Empty Cart" />
    <p class="friend-label">Оформление заказа</p>
    <p class="friend-tip">
      Последний шаг - заполните данные для оформления заказа и регистрации в
      системе лояльности
    </p>
      <form @submit.prevent="submitOrder">
        <div class="input_data">
          <p>ФИО</p>
          <input
            v-model="state.name"
            :class="{ 'is-invalid': $v.name.$error && formSubmitted }"
            placeholder="Иванов Иван Иванович"
          />
          <div class="invalid-feedback" v-if="formSubmitted && !$v.name.required">
            Имя обязательно
          </div>
        </div>
        <div class="input_data">
          <p>ТЕЛЕФОН</p>
          <input
            v-model="state.phone"
            placeholder="+7 999 999 99 99"
          />
          <div class="invalid-feedback" v-if="formSubmitted && !$v.phone.required">
            Телефон обязателен
          </div>
          <div class="invalid-feedback" v-if="formSubmitted && !$v.phone.numeric">
            Телефон должен быть числовым
          </div>
        </div>
        <div class="input_data">
          <p>АДРЕС</p>
          <input
            v-model="state.address"
            :class="{ 'is-invalid': $v.address.$error && formSubmitted }"
            placeholder="г. Москва, ул. Лобачевского, д.8"
          />
          <div class="invalid-feedback" v-if="formSubmitted && !$v.address.required">
            Адрес обязателен
          </div>
        </div>
        <div class="input_data">
          <p>ДАТА РОЖДЕНИЯ</p>
          <input
            v-model="state.dateOfBirth"
            :class="{ 'is-invalid': $v.dateOfBirth.$error && formSubmitted }"
            placeholder="01.01.1990" type="date"
          />
          <div class="invalid-feedback" v-if="formSubmitted && !$v.dateOfBirth.required">
            Дата рождения обязательна
          </div>
        </div>
        <label class="container">
          Согласен(на) на обработку персональных данных
          <input
            type="checkbox"
            v-model="state.agreeToPersonalData"
            required
            @invalid="setCustomMessage($event, 'Пожалуйста, подтвердите согласие на обработку персональных данных')"
            @change="clearCustomMessage($event)"
          />
          <span class="checkmark"></span>
        </label>
        <label class="container">
          Согласен(на) с
          <a href="https://github.com/paxlits">политикой конфиденциальности</a>
          <input
            type="checkbox"
            v-model="state.agreeToPrivacyPolicy"
            required
            @invalid="setCustomMessage($event, 'Пожалуйста, подтвердите согласие с политикой конфиденциальности')"
            @change="clearCustomMessage($event)"
          />
          <span class="checkmark"></span>
        </label>
        <button class="submit-order-btn" type="submit">
          Сохранить
        </button>
      </form>

  </div>
</template>
<script>
import { reactive, ref } from 'vue'
import { required, numeric } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const store = useStore()
    const router = useRouter()  // Access the router
    const state = reactive({
      name: '',
      phone: '',
      address: '',
      dateOfBirth: '',
      agreeToPersonalData: false,
      agreeToPrivacyPolicy: false
    })

    const formSubmitted = ref(false)

    const setCustomMessage = (event, message) => {
      event.target.setCustomValidity(message)
    }

    const clearCustomMessage = (event) => {
      event.target.setCustomValidity('')
    }

    const rules = {
      name: { required },
      phone: { required, numeric },
      address: { required },
      dateOfBirth: { required }
    }

    const $v = useVuelidate(rules, state)

    const submitOrder = async () => {
      formSubmitted.value = true
      $v.value.$touch()

      if ($v.value.$invalid) {
        console.log('Form validation failed:', $v.value)
      } else {

        if (state.agreeToPersonalData && state.agreeToPrivacyPolicy) {
          const formData = {
            name: state.name,
            phone: state.phone,
            address: state.address,
            dateOfBirth: state.dateOfBirth
          }

          const cartItems = store.getters.cart.map(item => ({
            id: item.id,
            name: item.description,
            price: store.getters.getDiscountedPrice(item),
            quantity: item.quantity
          }))

          console.log("FORM DATA: ", JSON.stringify({ formData, cartItems }))

          try {
            const response = await fetch('https://dedovagency.ru/backend/submit-lead', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ formData, cartItems })
            })
            const data = await response.json()
            console.log(data.message)
            router.push('/thankyou')  // Navigate to the thank you page
          } catch (error) {
            console.error('Error submitting lead:', error)
          }
        }
      }
    }

    return {
      state,
      $v,
      formSubmitted,
      submitOrder,
      setCustomMessage,
      clearCustomMessage
    }
  }
}
</script>

<style scoped>
.formOrder {
  padding: 20px;
  padding-bottom: 15vh;
}

.input_data {
  margin-bottom: 2rem; /* Increased spacing between form fields */
}

.input_data input {
  width: 100%;
  height: 48px; /* Increased input field height */
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.input_data p {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: block;
}

.submit-order-btn {
  margin-top: 2rem;
  height: 48px; /* Matched button height with inputs */
  width: 100%;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 14px;
  margin-top: 0.25rem;
}
</style>