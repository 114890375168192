<template>
  <div class="mini-apps_centre">
    <img class="login_svg" src="/static/img/Login.svg" alt="Login">
    <p class="give_more">Получайте больше</p>
    <p class="mini_p">Зарегистрируйтесь в системе<br> лояльности ProMedLine и получите<br> скидки до 20% и бонусы на покупки. </p>
    <button class="registration" @click="$emit('showRegistration')">Зарегистрироваться</button>
  </div>
</template>

<script>
export default {
  emits: ['showRegistration']
}
</script>

<style scoped>
.mini-apps_centre{
  margin-top: 15vh;
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.login_svg{
  width: 80px;
  height: 80px;
  gap: 0px;
  opacity: 1; /* Fixed opacity */
  transform: translate(-50%,0);
  margin-left: 50%;
  background-color: #FEFEFE;
  border-radius: 50%;
  padding: 10px;
}

.registration{
  background-color: #4378FF1A;
  border: 0;
  min-width: 42px;
  padding: 12px 14px 12px 14px;
  gap: 8px;
  border-radius: 12px;
  opacity: 1; /* Fixed opacity */
  color: #43D5CA;
  font-weight: 600;
  font-size: 15px;
  margin-top: 3vh;
}

.give_more{
  margin-top: 2vh;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.mini_p{
  font-size: 17px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  letter-spacing: -0.23000000417232513px;
  text-align: center;
  margin-top: 1vh;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
</style>