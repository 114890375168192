<template>
  <transition name="fade">
    <div v-if="show" class="notification">
      <div class="notification-content">
        {{ message }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'NotificationPopup',
  props: {
    message: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style>
.notification {
  position: fixed !important;
  top: 20px !important; /* Changed from bottom to top */
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: #43D5CA !important;
  color: white !important;
  padding: 16px 32px !important;
  border-radius: 8px !important;
  z-index: 999999 !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  min-width: 250px !important;
  text-align: center !important;
}

.notification-content {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 1.4 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease !important;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0 !important;
  transform: translate(-50%, -20px) !important; /* Changed translation direction */
}
</style>