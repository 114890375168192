<template>
  <div>
    <notification-popup
        :message="'Услуга добавлена в корзину'"
        :show="showNotification"
    />
    <div class="Catalog">
      <h1>Акции дня</h1>


      <!-- Modal Structure -->
      <div v-if="showModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
          <AppCategories @close="closeModal"/>
        </div>
      </div>

      <div class="p__kat" style="margin-bottom: 1rem;">
<!--          <div class="stocks">-->
<!--            <p class="p__stock">Акции</p>-->
<!--            <router-link class="see_all-link" to="/promotions">-->
<!--              <p class="see__all">Смотреть все</p>-->
<!--            </router-link>-->
<!--          </div>-->
          <div class="stock1" stl>
            <img class="doctor1" src="/static/img/doctor.png">
            <div class="flex1">
              <p class="p__main__stock">Выезд<br>со скидкой</p>
              <p class="p__second__stock">Для новых клиентов<br> выезд врача <span
                  class="span__stock">со скидкой 5%!</span></p>
              <button @click="navigateToItem(60)" class="more">Подробнее</button>
            </div>
          </div>
        </div>

<!--      &lt;!&ndash; Rest of your existing catalog code &ndash;&gt;
      <div class="sort-control">
        <div class="sort-button" @click="toggleSort">
          <input
              background="/static/img/Vector.svg"
              class="filter"
              readonly
              :value="sortActive
            ? `По цене (${sortDirection === 'asc' ? '↑' : '↓'})`
            : 'По цене'">
        </div>
      </div>-->

      <div v-if="products.length === 0" class="empty-promotion">
        <p>Нет акций</p>
      </div>
      <div v-else class="list-promotions">
        <div
            class="item-promotion"
            v-for="product in products"
            :key="product.id"
            @click="navigateToItem(product.id)">
          <div class="top-div__item-catalog">
            <img :src="product.image" alt="Product Image">
            <p data-v-4a272c9e="" style="
    background-color: #43d5ca;
    color: #fff;
    text-align: center;
    font-family: TT Firs Neue;
    font-weight: 600;
    position: relative;
    bottom: 59%;
    left: 8px;
    font-size: 11px;
    width: 90px;
    padding: 3px;
    border-radius: 11.52px;
">до 24 октября</p>
          </div>
          <div class="bottom-div__item-promotion">
            <p>{{ product.name }}</p>
                          <div class="old-price-and-new">
                <div class="old-new-price">
                  <template v-if="product.old_price">
                    <del class="oldPrice">{{ product.old_price }} ₽</del>
                    <p class="newPrice">{{ product.price }} ₽</p>
                  </template>
                  <template v-else>
                    <p class="newPrice">{{ product.price }} ₽</p>
                  </template>
                </div>
                            <button
                                class="add-to-cart-btn"
                                :class="{ 'in-cart': isInCart(product) }"
                                @click.stop="toggleCart(product)"
                            >
                              <svg
                                  v-if="isInCart(product)"
                                  class="cart-icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#ffffff"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12"></polyline>
                              </svg>

                              <svg
                                  v-else
                                  class="cart-icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#ffffff"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                              >
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                              </svg>
                            </button>
              </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import NotificationPopup from './NotificationPopup.vue'
import AppCategories from './AppCategories.vue'

export default {
  name: 'AppCatalog',
  components: {
    NotificationPopup,
    AppCategories
  },
  props: {
    category: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isCategoriesExpanded: false,
      sortDirection: 'asc',
      sortActive: false,
      showNotification: false,
      showModal: false
    }
  },
  created() {
    if (this.category !== null) {
      this.selectCategory(parseInt(this.category))
    }
  },
  watch: {
    category(newValue) {
      if (newValue !== null) {
        this.selectCategory(parseInt(newValue))
      }
    },
    showNotification(newVal) {
      console.log('Parent showNotification changed:', newVal)
    }
  },
  computed: {
    ...mapState(['cart', 'categories', 'selectedCategory']),
    ...mapGetters(['filteredPromotions']),
    products() {
      if (!this.sortActive) return this.filteredPromotions

      return [...this.filteredPromotions].sort((a, b) => {
        return this.sortDirection === 'asc'
            ? a.price - b.price
            : b.price - a.price
      })
    },
    cartTotal() {
      return this.cart.reduce((total, item) => total + (item.price * item.quantity), 0)
    },
    displayCategories() {
      if (this.selectedCategory === null) {
        return this.categories.slice(0, this.isCategoriesExpanded ? this.categories.length : 1);
      }

      const selectedCategory = this.categories[this.selectedCategory];
      const otherCategories = this.categories.filter((_, index) => index !== this.selectedCategory);
      return [selectedCategory, ...otherCategories].slice(0, this.isCategoriesExpanded ? this.categories.length : 1);
    }

  },
  methods: {
    ...mapActions(['addToCart', 'removeFromCart', 'selectCategory']),
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    getCategoryCount() {
      return this.$store.state.products.filter(p => p.category === 999).length
    },
    getCategoryColor() {
      return this.selectedCategory === 999 ? '#43D5CA' : 'inherit'
    },
    isInCart(product) {
      return this.cart.some(item => item.id === product.id)
    },
    navigateToItem(id) {
      this.$router.push({name: 'Item', params: {id}})
    },
    toggleCart(product) {
      event.preventDefault()
      event.stopPropagation()

      if (this.isInCart(product)) {
        this.removeFromCart(product)
      } else {
        this.addToCart(product)
        this.showNotification = true

        setTimeout(() => {
          this.showNotification = false
        }, 3000)
      }
    },
    toggleSort() {
      if (!this.sortActive) {
        this.sortActive = true
        this.sortDirection = 'asc'
      } else {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      }
    }
  }
}
</script>

<style scoped>
.Catalog {
  padding: 20px;
  padding-bottom: 100px;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 100vw;
  width: 100%;
}

/* Rest of your existing styles */
h1 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.categories-container {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 16px;
  cursor: pointer;
}

.add-to-cart-btn{
  padding: 0;
}

.categories-list {
  flex: 1;
}

.category-item {
  margin: 5px 0;
}

.category-name {
  margin-right: 6px;
}

.category-count {
  color: #707579;
}

.expand-arrow {
  margin-left: auto;
  margin-right: 12px;
  transition: transform 0.3s ease;
}

.expand-arrow.expanded {
  transform: rotate(180deg);
}

.sort-control {
  display: flex;
  margin-bottom: 30px;
}

.sort-button {
  position: relative;
  cursor: pointer;
}

.filter {
  cursor: pointer;
}

.sort-icon {
  transform: translate(-200%, 0);
}

.bottom-div__item-promotion > p {
  line-height: 1.0;
  font-size: 15px;
  height: 45px;
}

.bottom-div__item-promotion {
  padding: 10px;
}

.price {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.old-price-and-new > button {
  margin: 0;
  margin-left: auto;
}

.item-promotion {

  margin: 10px 0;
  cursor: pointer;
  transition: transform 0.2s ease;
    height: 250px !important;

}

.item-promotion:hover {
  transform: scale(1.02);
}

.old-price-and-new {
  margin-top: 12px;
  display: flex;
  justify-content: top;
  align-items: center;
}

.add-to-cart-btn.in-cart {
  background-color: #707579;
}

.top-div__item-catalog {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    height: 50%;
    background-color: #edf2ff;
    text-align: center;
    align-content: center;
}
.list-promotions {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 42vw 42vw;
     grid-template-rows: unset;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}
</style>