// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store.js';
//import { VueTelegramPlugin } from 'vue-tg'
import { useVuelidate } from '@vuelidate/core'

const app = createApp(App);

app.use(router); // Use the router
app.use(store);  // Use the store
//app.use(VueTelegramPlugin);
app.use(useVuelidate);
app.mount('#app'); // Mount the app

